import React from 'react'
import { useSelector } from 'react-redux'
import styles from './Success.module.scss'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { Link, navigate } from 'gatsby'
import { setSelectedOrder, setLoadingFalse, setLoadingTrue } from '../../store/actions'

type SuccessType = {

}

const Success: React.FC<SuccessType> = () => {
   const billetUrl = useSelector((state: any) => state.billetUrl);
   const successOrderNumber = useSelector((state: any) => state.successOrderNumber)

   return (
      <Container className={styles.container}>
         <div className={styles.mainDiv}>
            <div className={styles.borderBottom}>
               <p className={styles.title}>Seu pedido foi recebido</p>
               <span className={styles.font13}>Obrigado por sua compra!</span>
            </div>
            <div className={styles.divSuccess}>
               <CheckCircleOutlineRoundedIcon className={styles.checkIcon} />
               <span style={{ fontSize: '13px', paddingTop: '5px' }}>O número do seu pedido é</span>
               <span className={styles.orderNumber}>{successOrderNumber}</span> <br />
               <span style={{ fontSize: '13px' }}>Você receberá um email de<br /> confirmação do pedido </span>
               {billetUrl !== "" &&
                  <a href={billetUrl} target='_blank' className={styles.link}>
                     <Button variant='contained' color='primary' className={styles.buttons} style={{ width: '65%' }}>
                        Gerar boleto
                  </Button>
                  </a>
               }
               <Button variant='outlined' color='primary' onClick={() => { setSelectedOrder((+successOrderNumber)); navigate('/minha-conta/pedidos/') }} className={styles.buttons} style={{ width: '55%' }}>
                  Ver pedido
               </Button>
            </div>
         </div>
         <Button component={Link} to={'/'} className={styles.buttonBack} classes={{ label: styles.buttonLabel }} disableTouchRipple>Voltar para a loja</Button>
      </Container>
   )
}

export default Success
